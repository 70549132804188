.reports .invoices-number {
  color: var(--main-color);
  background: #dbdbdb;
  padding: 10px;
}
.reports table thead {
  background: #dbdbdb;
  color: #000;
}
.reports table {
  border-color: transparent;
  border-width: 0;
}
.reports table tr {
  text-align: right;
}
.reports table th {
  border: 0;
}
.reports table tr th:not(:first-child) {
  text-align: center;
}
.reports h6 {
  background: #9d31a0;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 10px;
  width: 100%;
  color: #fff;
}
.reports .nav-tabs .nav-item.show .nav-link,
.reports .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #9d31a0;
  border-color: #dee2e6 #dee2e6 #fff;
}
.report .tab-content {
  border-radius: 0.75rem;
}

.Pending {
  color: rgb(70, 70, 203);
}

.Valid {
  color: green;
}

.Invalid {
  color: red;
}

.Submitted {
  color: rgb(176, 45, 176);
}

.Rejected {
  color: firebrick;
}
