.custom-nav {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #fff;
  transition: all 0.1s ease-out;
  height: 70px;
  align-items: center;
}
.logo-brand {
  font-size: 24px;
  text-transform: capitalize;
  color: var(--main-color);
}
.icon {
  font-size: 30px;
  color: var(--main-color);
  margin-right: 30px;
  cursor: pointer;
  display: inline-block;
}
/* @media (max-width: 767px) {
  .icon {
    display: inline-block;
  }
} */
@media (max-width: 480px) {
  .custom-nav .logout-btn {
    display: none;
  }
}
