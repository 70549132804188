.Homecard {
  transition: transform 0.2s ease-in-out;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;

  border: none;
}
.Homecard:hover {
  transform: translateY(-5px);
}

.homeCard-icon {
  color: #d04d94;
}

.Homecard .card-body {
  color: rgb(70, 70, 70) !important;
}
.Homecard .card-body div {
  padding: 10px;
  font-size: 35px;
}
