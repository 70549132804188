@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #d04d94;
  --alt-main-color: #d04d94;
  --variant-color: #9d31a0;
}
body {
  /* background-image: url('../src/images/background.avif'); */
  background: #f5f5f5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  font-family: 'Cairo' !important;
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  display: inline-block;
}
li {
  list-style: none;
}
p {
  margin-bottom: 0 !important;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border-color: inherit;
}

svg {
  cursor: pointer;
}
.btn-primary:active:focus {
  box-shadow: none;
}
/*Login */

.login-card {
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  background: #fff;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 360px;
}
.login-logo {
  margin: 50px 0px 10px 0px;
}
.form-content {
  width: 100%;
}

h5 {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  color: #495057;
}

.form-content .btn {
  display: block;
  width: 100%;
  padding: 0.6rem 0.9rem;
  background-color: var(--main-color);
  color: #fff;
}
.form-content .btn:hover {
  background-color: var(--alt-main-color);
}
/*Start page-content */
@media (min-width: 768px) {
  .page-content {
    margin-right: 280px;
  }
  .sidebar.active + .page-content {
    margin-right: 0px;
  }
}

.page-content {
  transition: all 0.3s;
  padding: calc(70px + 1.5rem) calc(1.5rem * 0.5) 60px calc(1.5rem * 0.5);
}
.page-content .page-title {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.search-box {
  position: relative;
}
.search-box .form-control {
  max-width: 400px;
  padding: 0.5rem 0.9rem !important;
  padding-right: 40px !important;
  border: 0;
}
.search-box .search-icon {
  font-size: 18px;
  position: absolute;
  right: 13px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: #76797e;
}

/*End page-content */

/*Start Admin */
.table-icon {
  cursor: pointer;
}
.table-icon {
  display: block;
}
.table-icon.edit:hover svg {
  color: green;
}
.table-icon.edit:hover {
  color: #121229;
}
.table-icon.delete:hover {
  color: red;
}
#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 30px;
}
.file-icon {
  padding: 8px;
  background-color: var(--main-color);
  color: #fff;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid var(--main-color);
  transition: 0.3s all;
}
input[type='file']::file-selector-button {
  background: #9d31a0;
  border: 0;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
}
.file-icon:hover {
  background-color: #fff;
  color: var(--main-color);
}
.company-btn {
  width: 100%;
  height: 35px;
  background-color: var(--main-color);
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 0.9rem;
  border: none;
}
@media (max-width: 575px) {
  .company-btn {
    width: 30%;
  }
}
.company-info .form-check .form-check-input {
  float: none;
  margin-left: 15px;
}
.company-info .form-switch {
  margin-top: 5px;
}

/* e-invoic*/
.e-invoic-tab .card {
  position: relative;
  border-radius: 0.375rem;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.e-invoic-title {
  position: absolute;
  top: -10px;
  background: white;
  color: var(--main-color);
}
.e-invoic-tab .card-body > div {
  direction: ltr;
}
.e-invoic-tab .card-body > div label {
  color: var(--main-color);
}
.nav-tabs {
  width: fit-content;
  /* background: #6c757d; */
  color: #495057;
  background-color: #fff;
  border-bottom: 0;
}
.nav-tabs .nav-link {
  color: #495057;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tab-content {
  border: 1px solid #eee;
  padding: 15px;
  background: white;
}
.company .nav-tabs .nav-item.show .nav-link,
.company .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #9d31a0;
  border-color: #dee2e6 #dee2e6 #fff;
}
/*End Admin */
/*btn */
.btn-custom {
  padding: 0.375rem 0.75rem;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--main-color);
  float: left;
  padding: 10px 15px;
  border: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 0.375rem;
}
.btn-custom-disable {
  background-color: #d04d957a !important;
}
.btn-custom-disable:hover {
  background-color: #d04d957a;
}
.btn-custom:hover {
  background-color: var(--main-color);
  border: 0;
}
.btn-custom:active,
.btn-custom:focus {
  background-color: var(--main-color);
  border: 0;
  outline: none;
}
.btn {
  border: 0;
}
.btn:first-child:active {
  box-shadow: none;
  background-color: var(--main-color);
  outline: none;
  color: #fff;
}
.main-btn {
  padding: 0.6rem 0.9rem;
  background-color: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
}
.main-btn:hover {
  background-color: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.main-btn:focus {
  outline: 0;
  box-shadow: none;
}
/* */

tr {
  white-space: nowrap;
  text-align: center;
}
.card-body {
  overflow-x: auto;
}

td a {
  color: inherit;
}
.spinner-table {
  font-size: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
}
@media (max-width: 480px) {
  .nav-tabs {
    flex-wrap: none;
    flex-direction: column;
  }
  .nav-tabs .nav-link {
    width: 100%;
  }
}

.logout-btn {
  background-color: var(--main-color);
  color: #fff;
}
.logout-btn:hover {
  background-color: var(--main-color);
  color: #fff;
}
.btn:focus {
  box-shadow: none;
}

td a.name {
  border-bottom: 1px solid #212529;
}
td a.name:hover {
  color: #212529;
}

.single-info h6 {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  background: #9d31a0;
  color: #fff;
  font-size: 18px;
}
.single-info table {
  width: 90%;
  margin: 15px auto;
}
.single-info table td {
  font-size: 15px;
}
.single-info table tr td:first-child {
  font-weight: 500;
}

/*Contact */
.contact .info-box {
  color: #444;
  text-align: center;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
  padding: 20px 20px 30px 20px;
  margin-bottom: 30px;
  height: 160px;

  transition: 0.3s all;
}
.contact .info-box svg {
  color: var(--main-color);
  font-size: 2em;
  cursor: none;
}
.info-box h3 {
  font-size: 1.25em;
  color: #666;
  font-weight: 700;
  margin: 10px 0;
}
.info-box p {
  color: #787976;
  padding: 0;
  line-height: 24px;
  font-size: 0.875em;
  margin-bottom: 0;
}
.info-box a {
  color: #787976;
  text-decoration: underline;
}

.contact .info-box:hover {
  background-color: var(--main-color);
}
.contact .info-box:hover svg,
.contact .info-box:hover h3,
.contact .info-box:hover a,
.contact .info-box:hover p {
  color: #fff;
}
/*footer*/

.sidebar.active + footer {
  margin-right: 0px;
}

footer {
  background-color: var(--main-color);
  color: white;
  text-align: center;
  padding: 20px calc(1.5rem * 0.5);
  margin-right: 280px;

  display: flex;
  justify-content: center;
  align-items: center;
}
footer img {
  width: 100px;
  margin-right: 10px;
}

footer p {
  margin-bottom: 0;
}
.page-content {
  min-height: 100vh;
}

.dashboard_container {
  background-color: #eee;
}

thead {
  background: #9d31a0;
  color: #fff;
}
.modal-header {
  background: #9d31a0;
}
.modal-title {
  color: #fff;
  font-size: 18px;
}
.card {
  border-radius: 0.75rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
}

.card-body::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.625rem;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #9d31a0;
  border-radius: 0.625rem;
}

.form-check-input:checked {
  background-color: #9d31a0;
  border-color: #9d31a0;
}
.form-check-input:focus {
  box-shadow: none;
}

.single-info div.overflow-auto::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.625rem;
}

.single-info div.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #9d31a0;
  border-radius: 0.625rem;
}

input[type='checkbox'] {
  accent-color: #9d31a0;
}

.Toastify__toast-body > div:last-child {
  text-align: right;
  margin-right: 10px;
}
@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 90% !important;
    margin-top: 10px !important;
  }
  .backage {
    width: 100% !important;
    font-size: 9px;
  }
}
.btn-icon-custom {
  padding: 0.5rem 0.8rem !important;
}

.modal.form-modal {
  --bs-modal-width: 800px;
}
/* .card-search{
  height: 100% !important;
} */
.card-search {
  height: 600px;
}

.p-datatable .p-column-header-content {
  height: 70px !important;
  color: white !important;
  background-color: #9d31a0 !important;
  justify-content: center !important;
  min-width: 130px !important;
}

.p-column-header-content .p-column-title {
  white-space: normal;
  overflow-wrap: break-word;
}

.p-datatable-tbody tr {
  height: 60px;
}

.p-datatable-tbody td {
  text-align: center;
  padding: 0px 10px;
  border: 1px solid #dee2e6;
}

.p-paginator {
  height: 70px !important;
}

.p-paginator .p-paginator-icon {
  rotate: 180deg !important;
  margin-top: 3px !important;
}

.tableActions_container {
  background-color: white;
  border-radius: 5px 5px 0 0;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 30px;
  padding-inline: 20px;
}

.tableSearch_container {
  flex: 1;

  display: flex;
  align-items: center;
  gap: 10px;
}

.tableSearch_container input {
  border: 1px solid #ced4da !important;
  width: 100% !important;
  height: 40px !important;
  border-radius: 5px !important;
  padding-inline-start: 15px !important;
  box-shadow: none !important;
}

.tableSearch_container button {
  width: 150px !important;
  height: 40px !important;
  border-radius: 5px !important;
  background-color: #d04d94;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
}

.tableFilters_container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tableActions .p-dropdown {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da !important;
  background: #ffffff !important;
  width: 22px !important;
  height: 22px !important;
  color: #495057 !important;
  border-radius: 6px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2196f3 !important;
  background: #2196f3 !important;
  color: white !important;
}

.tableActions .p-dropdown-label {
  padding: 7px 15px;
}

.tableActions .p-dropdown-trigger {
  padding: 0 15px !important;
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.p-paginator .p-dropdown {
  padding: 2px 15px;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-first,
.p-paginator-prev,
.p-paginator-current {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  user-select: none;
  overflow: hidden;
  position: relative;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator .p-dropdown-label {
  padding: 12px 20px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #eff6ff;
  border-color: #eff6ff;
  color: #1d4ed8;
}

.p-dropdown-items {
  transition: colors 0.3s ease-in-out;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-dropdown-items .p-dropdown-item {
  height: 50px;
  padding-inline: 15px;
  padding-top: 10px;
}
.successButton:hover {
  background-color: #2ecc71 !important;
  cursor: pointer;
}
.errorButton:hover {
  background-color: #c0392b !important;
  cursor: pointer;
}

.changeCompanyButton:hover {
  background-color: #c0392b !important;
}
