.sidebar {
  width: 0;
  right: -100%;
  z-index: 1002;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  box-shadow: 0 2px 2px rgb(15 34 58 / 8%);
  transition: all 0.1s ease-out;
  padding: 0.531rem 1.5rem;
  overflow-y: auto;
}
.sidebar.active {
  width: 280px;
  right: 0;
}
.sidebar::-webkit-scrollbar {
  width: 7px;
  height: 8px;
 
  background-color: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #d04d95b6;
  border-radius: 3px;
  opacity: .3;
}
.sidebar .nav-item .nav-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
  color: #5d6064;
  font-weight: bold;
  background: rgba(230, 230, 230, 0.205);
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0px;
}
.sidebar .nav-item .nav-link:hover {
  color: black;
  font-weight: bold;
}
.sidebar .nav-item .nav-link.active {
  color: white;
  background: var(--main-color);
}
.sidebar .nav-link .arrow-icon {
  position: absolute;
  left: 10px;
}
.sidebar
  .nav-item
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]
  .arrow-icon {
  transform: rotate(270deg);
}

@media (min-width: 768px) {
  .sidebar {
    width: 280px;
    right: 0;
    transition: all 0.3s;
  }
  .sidebar.active {
    width: 0;
    right: -100%;
  }
}
.sidebar .logout {
  display: none;
}
@media (max-width: 480px) {
  .sidebar .logout {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .logout-item {
    display: none;
  }
}

.menu-dropdown li.nav-item a.nav-link {
  margin-right: 18px;
}

/* .menu-dropdown .nav-link:before {
  content: "";
  width: 5px;
  height: 1px;
  border: 1px solid #5d6064;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 50%;
  transition: all 0.4s ease-in-out;
} */
.menu-dropdown li.nav-item:hover {
  color: var(--main-color);
}
.menu-dropdown .nav-link.active {
  color: var(--main-color);
}
.menu-dropdown .nav-link:hover:before {
  border-color: var(--main-color);
  width: 10px;
}
.menu-dropdown .nav-link.active:before {
  border-color: var(--main-color);
  width: 10px;
}

.sidebar > li.nav-item {
  padding-top: 0.5rem;
}
