/* pagination */
.page-link {
  background-color: #fff !important;
  color: #9D31A0 !important;
  border-radius: 0 !important;
}
 .active-page .page-link {
  background-color: #9D31A0 !important;
  color: white !important;
  border-color: #9D31A0 !important;
}
.page-link:hover {
  background-color: #9D31A0 !important;
  color: white !important;
}
.pagination {
  --bs-pagination-focus-box-shadow: none !important;
}
.page-item.disabled .page-link {
  color: #cfd3d4 !important;
}
/* end pagination */
