.single-company {
  display: flex;
}
.single-company .username:after:not(:last-of-type) {
  content: "/";
}
.single-company td {
  white-space: pre-wrap;
}
@media (max-width: 580px) {
  .single-company {
    flex-direction: column;
  }
  .w-50 {
    width: 100% !important;
    margin-bottom: 15px;
  }
  h6 {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
}
