
.filter{
    height: 80vh;
}

.filter-btn{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0.375rem 0.75rem;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--main-color);
    padding: 10px 15px;
    border: 0;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 0.375rem;
}
.filter-btn a{ 
    color: white;

}
.form-check{
    width: 40%;
}